<template>
    <v-container fluid>
        <ReadHeader :current="current" />

        <v-row>
            <v-col>
                <KeyValue
                :title="`License`"
                :value="entityCore.license"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`DIR Project Identifier`"
                :value="entityCore.dir_project_identifier"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`Phone`"
                :value="entityCore.phone"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Fax`"
                :value="entityCore.fax"
                ></KeyValue>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <KeyValue
                :title="`Email`"
                :value="entityCore.email"
                ></KeyValue>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <KeyValue
                :title="`Address`"
                :value="entityCore.address_line_1"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Address Continued`"
                :value="entityCore.address_line_2"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`City`"
                :value="entityCore.city"
                ></KeyValue>
            </v-col>
            <v-col cols="3">
                <KeyValue
                :title="`State`"
                :value="entityCore.state"
                ></KeyValue>
            </v-col>
            <v-col cols="3">
                <KeyValue
                :title="`Zip`"
                :value="entityCore.zip"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row v-if="entityCore.social_program_identifiers">
            <v-col>
                <h3>Social Program Identifiers</h3>
                <v-list lines="one">
                    <v-list-item
                        v-for="program in entityCore.social_program_identifiers"
                        :key="program"
                        :title="jsonValueToTitle(socialProgramIdentifiers, program)"
                        density="compact"
                    ></v-list-item>
                </v-list>
            </v-col>
        </v-row>
        
        <v-row v-if="entityCore.associated_owners">
            <v-col>
                <h3>Associated Owners</h3>
                <v-list lines="one">
                    <v-list-item
                        v-for="ownerId in entityCore.associated_owners"
                        :key="ownerId"
                        :title="nameById(ownerId)"
                        density="compact"
                    ></v-list-item>
                </v-list>
            </v-col>
        </v-row>
        
        <TableList
            :current="current"
            :details="clientContactsDetails"
        />

        
    </v-container>
</template>
<script setup>
import { reactive, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useIsCurrentEntityLoaded, useEntityCore, useNameById, useFetchSingle, useJsonValueToTitle } from "@/assets/modules/composables.js";
import socialProgramIdentifiers from "@/assets/selects/socialProgramIdentifiers.json";
import KeyValue from "@/components/interface/Key-Value.vue";
import TableList from "@/components/dataversiontwo/interface/TableList.vue";
import ReadHeader from "@/components/dataversiontwo/interface/ReadHeader.vue";

const route = useRoute();
const { entityCore } = useEntityCore();
const { isCurrentEntityLoaded } = useIsCurrentEntityLoaded();
const { nameById } = useNameById();
const isLoading = reactive({});
const alreadyFetchedData = [];
const { fetchSingle } = useFetchSingle(isLoading, alreadyFetchedData);
const { jsonValueToTitle } = useJsonValueToTitle();

const current = {
    type: "contractor",
    id: route.params.entity_id,
};

const clientContactsDetails = {
    listType: "contractor_contact",
    heading: "Contractor Contacts",
    tableHeaders: [
        {
            title: "ID",
            key: "_id",
        }
    ],
};


// After the main entity loads, get the data for each associated owner
watch(isCurrentEntityLoaded, async () => {
    if(isCurrentEntityLoaded.value && entityCore.value.associated_owners){
        entityCore.value.associated_owners.forEach((ownerId) => {
            fetchSingle("owner", ownerId);
        });
    }
})


</script>