import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';


function useFormCancel() {
    const router = useRouter();

    function formCancel(redirectPath) {
        if (window.history.length > 2) {
            router.go(-1);
        } else {
            router.push(`/dataversiontwo/${redirectPath}`);
        }
    }

    return { formCancel };
}

function useIsCurrentEntityLoaded() {
    const store = useStore();
    const isCurrentEntityLoaded = computed(() => { 
        return store.getters.isCurrentEntityLoaded;
    })

    return { isCurrentEntityLoaded };
}

function useTableRowClick(){
    const router = useRouter();
    function tableRowClick(item, row){
        router.push(`/dataversiontwo/${row.item._type}/${row.item._id}`);
    }
    return { tableRowClick };
}

function useEntityCore(){
    const store = useStore();
    const route = useRoute();
    const entityCore = computed(() => { 
        return store.getters.getEntityCore(route.params.entity_id);
    })
    return { entityCore };
}

function useEntityAncestry(){
    const store = useStore();
    const route = useRoute();
    const docId = route.params.entity_id ? route.params.entity_id : route.params.parent_id;
    const entityAncestry = computed(() => {
        return convertPathToArray(docId);
    })

    function convertPathToArray(childId){
        const child = store.getters.getEntityDocument(childId);
        const path = child?._path || "";
        const result = {};
        if(path){
            const elements = path.split('.');
            for (let i = 0; i < elements.length; i += 2) {
                result[elements[i]] = elements[i + 1];
            }
        }
        return result
    }

    return { entityAncestry };
}

function useFetchList(isLoading, alreadyFetchedData){
    const store = useStore();
    async function fetchList(entityType, lookupValue){
        const fetchId = `${entityType}_${lookupValue}`;
        if(!alreadyFetchedData.includes(fetchId)){
            alreadyFetchedData.push(fetchId);
            isLoading[fetchId] = true;
            await store.dispatch("entityGlobalList", {
                entityType: entityType,
                lookupValue: lookupValue,
            });
            isLoading[fetchId] = false;
        }
    }
    return { fetchList };
}

function useFetchSingle(isLoading, alreadyFetchedData){
    const store = useStore();
    async function fetchSingle( entityType, entityId){
        const fetchId = `${entityType}_${entityId}`;
        if(!alreadyFetchedData.includes(fetchId)){
            alreadyFetchedData.push(fetchId);
            isLoading[fetchId] = true;
            await store.dispatch("entityGlobalRead", {
                entityType: entityType,
                entityID: entityId,
            });
            isLoading[fetchId] = false;
        }
    }
    return { fetchSingle };
}

function useNameById(){
    const store = useStore();
    function nameById(unitPriceBookId, emptyRetun = "Loading..."){
        return store.getters.getEntityDocument(unitPriceBookId)?._name || emptyRetun;
    }
    return { nameById };
}

function useFieldValidation(entityCore){
    function dashesAndNumbersOnly(event, fieldName, maxLength) {
        let input = event.target.value.replace(/[^0-9-]/g, '');
        if (input.length > maxLength) {
            input = input.slice(0, maxLength);
        }
        entityCore.value[fieldName] = input;
    }
    function alphanumericAndDashesOnly(event, fieldName) {
        let input = event.target.value.replace(/[^a-zA-Z0-9-]/g, '');
        entityCore.value[fieldName] = input;
    }
    function convertEmptyToNull(field) {
        if(entityCore.value[field] === "" || entityCore.value[field] === undefined){
            entityCore.value[field] = null;
        }
    }
    function numbersOnly(event, fieldName) {
        let input = event.target.value.replace(/[^0-9]/g, '');
        entityCore.value[fieldName] = input;
    }
    function numbersAndDecimalsOnly(event, fieldName) {
        let input = event.target.value.replace(/[^0-9.]/g, '');
        entityCore.value[fieldName] = input;
    }
    function convertToNumber(field) {
        convertEmptyToNull(field);
        if(entityCore.value[field] !== null){
            if(entityCore.value[field].toString().includes('.')){
                let parts = entityCore.value[field].split('.');
                if (parts.length < 3) {
                    entityCore.value[field] = Number(entityCore.value[field]);
                }
            }else{
                entityCore.value[field] = Number(entityCore.value[field]);
            }
        }
    }
    function convertToDateObject(event,field) {
        const date = new Date(event.target.value);
        if (!isNaN(date.getTime())) {
            entityCore.value[field] = date;
        } else {
            entityCore.value[field] = null;
        }
    }
    return { dashesAndNumbersOnly, alphanumericAndDashesOnly, convertEmptyToNull, numbersOnly, numbersAndDecimalsOnly, convertToNumber, convertToDateObject };
}

function useFormatting(){
    function formatDate(dateString) {
        if(dateString){
            const date = new Date(dateString);
            const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
            return formattedDate;
        }else{
            return null;
        }
    }
    return { formatDate };
}

function useToDateObject(entityCore){
    function toDateObject(value){
        entityCore.value[value] = parseDateString(entityCore.value[value]);
    }
    function parseDateString(value){
        if (typeof value === 'string' && !isNaN(Date.parse(value))){
            value = new Date(value);
        }
        return value;
    }
    return { toDateObject };
}

function useJsonValueToTitle(){
    function jsonValueToTitle(jsonObject, value){
        return jsonObject.find((program) => program.value === value).title;
    }
    return { jsonValueToTitle };
}


export { 
    useFormCancel,
    useIsCurrentEntityLoaded,
    useTableRowClick,
    useEntityCore,
    useEntityAncestry,
    useFetchList,
    useFetchSingle,
    useNameById,
    useFieldValidation,
    useFormatting,
    useToDateObject,
    useJsonValueToTitle,
};