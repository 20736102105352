<template>
    <v-container fluid>
        <v-form ref="form" lazy-validation>
            <v-row>
                <v-col>
                    <h1>Create Contractor</h1>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.name"
                        label="Name*"
                        placeholder="Name"
                        hint="Minimum of 3 characters required"
                        persistent-hint
                        validate-on="blur"
                        :rules="[
                            rules.required, 
                            rules.min(3), 
                            rules.max(64)
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.license"
                        label="License"
                        placeholder="License"
                        validate-on="blur"
                        @blur="convertEmptyToNull('license')"
                    >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="entityCore.dir_project_identifier"
                        label="DIR Project Identifier"
                        placeholder="DIR Project Identifier"
                        validate-on="blur"
                        @blur="convertEmptyToNull('dir_project_identifier')"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.phone"
                        label="Phone"
                        placeholder="Phone"
                        hint="###-###-####"
                        persistent-hint
                        validate-on="blur"
                        @input="dashesAndNumbersOnly($event, 'phone', 12);"
                        @blur="convertEmptyToNull('phone')"
                        :rules="[
                            rules.phone,
                        ]"
                    >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="entityCore.fax"
                        @input="dashesAndNumbersOnly($event, 'fax', 12);"
                        label="Fax"
                        placeholder="Fax"
                        hint="###-###-####"
                        persistent-hint
                        validate-on="blur"
                        @blur="convertEmptyToNull('fax')"
                        :rules="[
                            rules.phone,
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.email"
                        label="Email"
                        placeholder="Email"
                        validate-on="blur"
                        @blur="convertEmptyToNull('email')"
                        :rules="[
                            rules.email,
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.address_line_1"
                        label="Address 1"
                        placeholder="Address 1"
                        validate-on="blur"
                        @blur="convertEmptyToNull('address_line_1')"
                        :rules="[
                            rules.min(3),
                            rules.max(64),
                        ]"
                    >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="entityCore.address_line_2"
                        label="Address 2"
                        placeholder="Address 2"
                        validate-on="blur"
                        @blur="convertEmptyToNull('address_line_2')"
                        :rules="[
                            rules.min(2),
                            rules.max(64),
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.city"
                        label="City"
                        placeholder="City"
                        validate-on="blur"
                        @blur="convertEmptyToNull('city')"
                        :rules="[
                            rules.min(2),
                            rules.max(64),
                        ]"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-select
                        v-model="entityCore.state"
                        :items="statesList"
                        item-title="state"
                        item-value="code"
                        label="State"
                        placeholder="State"
                        @blur="convertEmptyToNull('state')"
                    >
                    </v-select>
                </v-col>
                <v-col cols="3">
                    <v-text-field
                        v-model="entityCore.zip"
                        label="Zip"
                        placeholder="Zip"
                        validate-on="blur"
                        @input="dashesAndNumbersOnly($event, 'zip', 10);"
                        @blur="convertEmptyToNull('zip')"
                        hint="##### or #####-####"
                        persistent-hint
                        :rules="[
                            rules.zip,
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <h3>Social Program Identifiers</h3>
                    <v-checkbox
                        v-for="option in socialProgramIdentifiers"
                        :key="option._id"
                        v-model="entityCore.social_program_identifiers"
                        :label="option.title"
                        :value="option.value"
                        density="compact"
                        hide-details="true"
                    ></v-checkbox>
                </v-col>
            </v-row>

            <v-row v-if="owners.length">
                <v-col>
                    <h3>Associated Owners</h3>
                    <v-checkbox
                        v-for="entity in owners"
                        :key="entity._id"
                        v-model="entityCore.associated_owners"
                        :label="entity._name"
                        :value="entity._id"
                        density="compact"
                        hide-details="true"
                    ></v-checkbox>
                </v-col>
            </v-row>

            <FormCreateFooter 
                :form="form"
                :entityCore="entityCore"
                :current="current"
                formCancelRedirect="contractors"
            />
        </v-form>
    </v-container>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, reactive, ref, onMounted } from 'vue';
import rules from "@/assets/modules/rules.js";
import statesList from "@/assets/selects/state.json";
import socialProgramIdentifiers from "@/assets/selects/socialProgramIdentifiers.json";
import FormCreateFooter from "@/components/dataversiontwo/interface/FormCreateFooter.vue";
import { useFetchList, useFieldValidation } from "@/assets/modules/composables.js";

const form = ref();
const store = useStore();
const isLoading = reactive({});
const alreadyFetchedData = [];
const { fetchList } = useFetchList(isLoading, alreadyFetchedData);
const entityCore = ref({
    associated_owners: [],
    social_program_identifiers: [],
});
const { convertEmptyToNull, dashesAndNumbersOnly } = useFieldValidation(entityCore);

const current = {
    type: "contractor"
};


const owners = computed(() => {
    return store.getters.getChildList("owner.", "owner");
})


onMounted(() => {
    fetchList("owner",'all');
})
</script>