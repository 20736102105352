<template>
    <v-container fluid>
        <ReadHeader :current="current" />
        
    </v-container>
</template>
<script setup>
import { useRoute } from 'vue-router';
import { useEntityCore } from "@/assets/modules/composables.js";
import ReadHeader from "@/components/dataversiontwo/interface/ReadHeader.vue";
const route = useRoute();

// eslint-disable-next-line no-unused-vars
const { entityCore } = useEntityCore();

const current = {
    type: "unit_price_book",
    id: route.params.entity_id,
};
</script>