<template>
    <v-container fluid>
        <ReadHeader :current="current" />

        <v-row>
            <v-col>
                <KeyValue
                :title="`Work Order Number`"
                :value="entityCore.work_order_number"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Status`"
                :value="entityCore.work_order_status"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`Contract`"
                :value="nameById(entityCore.contract_uid)"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Stage`"
                :value="entityCore.stage"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`Location Info`"
                :value="entityCore.location_info"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`Scope of Work`"
                :value="entityCore.scope_of_work"
                ></KeyValue>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <h3>Owner Project Managers</h3>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <KeyValue
                :title="`Primary Owner Project Manager`"
                :value="nameById(entityCore.owner_project_manager_primary_uid)"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Secondary Owner Project Manager`"
                :value="nameById(entityCore.owner_project_manager_2_uid, null)"
                ></KeyValue>
            </v-col>
        </v-row>
       
        <v-row>
            <v-col cols="6">
                <KeyValue
                :title="`Tertiary Owner Project Manager`"
                :value="nameById(entityCore.owner_project_manager_3_uid, null)"
                ></KeyValue>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <h3>Contractor Project Managers</h3>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`Primary Contractor Project Manager`"
                :value="nameById(entityCore.contractor_project_manager_primary_uid)"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Secondary Contractor Project Manager`"
                :value="nameById(entityCore.contractor_project_manager_2_uid, null)"
                ></KeyValue>
            </v-col>
        </v-row>
       
        <v-row>
            <v-col cols="6">
                <KeyValue
                :title="`Tertiary Contractor Project Manager`"
                :value="nameById(entityCore.contractor_project_manager_3_uid, null)"
                ></KeyValue>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <h3>Approvers</h3>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <KeyValue
                :title="`Inspector`"
                :value="nameById(entityCore.inspector_uid)"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`RFP Signing Authority`"
                :value="nameById(entityCore.rfp_signing_authority_uid)"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`NTP Signing Authority`"
                :value="nameById(entityCore.ntp_signing_authority_uid)"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`WOA Signing Authority`"
                :value="nameById(entityCore.woa_signing_authority_uid)"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <h3>Calculated Dates</h3>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`Construction Start Date`"
                :value="formatDate(entityCore.construction_start_date)"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Time Extension`"
                :value="entityCore.time_extension + ' days'"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`Construction Start to Substantial Completion`"
                :value="entityCore.start_to_substantial_completion_days + ' days'"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Substantial to final Completion`"
                :value="entityCore.substantial_to_completion_days + ' days'"
                ></KeyValue>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <KeyValue
                :title="`Substantial Completion Date`"
                :value="formatDate(entityCore.calculated_substantial_completion_date)"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Final Completion Date`"
                :value="formatDate(entityCore.calculated_final_completion_date)"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`Extended Substantial Completed Date`"
                :value="formatDate(entityCore.calculated_extended_substantial_completion_date)"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Extended Final Completion Date`"
                :value="formatDate(entityCore.calculated_extended_final_completion_date)"
                ></KeyValue>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <h3>Administrative Dates</h3>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <KeyValue
                :title="`Site Walk Through Date`"
                :value="formatDate(entityCore.site_walk_through_date)"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`RFP Issue Date`"
                :value="formatDate(entityCore.rfp_issue_date)"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`Proposal Due Date`"
                :value="formatDate(entityCore.proposal_due_date)"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Accepted Proposal Date`"
                :value="formatDate(entityCore.accepted_proposal_date)"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`Work Order Authorization Date`"
                :value="formatDate(entityCore.work_order_authorization_date)"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`NTP Issued Date`"
                :value="formatDate(entityCore.ntp_issued_date)"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`EEO Clearance Date`"
                :value="formatDate(entityCore.eeo_clearance_date)"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Notice of Completion File Date`"
                :value="formatDate(entityCore.notice_of_completion_file_date)"
                ></KeyValue>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <h3>Fees & Damages</h3>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <KeyValue
                :title="`Liquidated Damages to District`"
                :value="liquidatedDamagesToDistrict"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Liquidated Damages to Contractor`"
                :value="liquidatedDamagesToContractor"
                ></KeyValue>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <KeyValue
                :title="`JAMB Fee Percentage`"
                :value="jambFeePerecentage"
                ></KeyValue>
            </v-col>
        </v-row>

        <TableList
            :current="current"
            :details="proposalsDetails"
        />
        
        <TableList
            :current="current"
            :details="supplementalWorkOrdersDetails"
        />

        <v-row><v-col><v-divider></v-divider></v-col></v-row>

        <v-row>
            <v-col>
                <h3>Job Order Approvals</h3>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-data-table
                    :headers="tableHeaders"
                    :items="fileList"
                    item-value="Key"
                    :items-per-page="100"
                >
                <template v-slot:loading>
                <v-skeleton-loader
                    type="table-row-divider@9,table-row@1"
                ></v-skeleton-loader>
                </template>
                
                
                <template v-slot:[`item.Key`]="{ item }">
                {{ getFileName(item.Key) }}
                </template>
                <template v-slot:[`item.Size`]="{ item }">
                {{ bytesToSize(item.Size) }}
                </template>

                <template v-slot:[`item.LastModified`]="{ item }">
                    <span v-html="getCreatedDate(item.LastModified)"></span>
                </template>

                <template v-slot:[`item.Action`]="{ item }">
                <v-btn
                    variant="plain"
                    icon="mdi-eye"
                    @click.stop="download(item.Key)"
                    size="small"
                    title="Download"
                ></v-btn>

                </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn color="primary" variant="outlined" @click="generateJobOrderApproval()">
                    Generate Job Order Approval
                </v-btn>
            </v-col>
        </v-row>

    </v-container>
</template>
<script setup>
import { useRoute } from 'vue-router';
import { reactive, onMounted, computed, watch, ref } from 'vue';
import ReadHeader from "@/components/dataversiontwo/interface/ReadHeader.vue";
import KeyValue from "@/components/interface/Key-Value.vue";
import TableList from "@/components/dataversiontwo/interface/TableList.vue";
import { useEntityCore, useIsCurrentEntityLoaded, useFetchSingle, useFetchList, useNameById, useFormatting } from "@/assets/modules/composables.js";

const route = useRoute();
const isLoading = reactive({});
const alreadyFetchedData = [];
const { entityCore } = useEntityCore();
const { isCurrentEntityLoaded } = useIsCurrentEntityLoaded();
const { fetchSingle } = useFetchSingle(isLoading,alreadyFetchedData);
const { fetchList } = useFetchList(isLoading,alreadyFetchedData);
const { nameById } = useNameById();
const { formatDate } = useFormatting();

const current = {
    type: "work_order",
    id: route.params.entity_id,
};

const proposalsDetails = {
    listType: "proposal",
    heading: "Proposals",
    tableHeaders: [
        {
            title: "ID",
            key: "_id",
        }
    ],
};
const supplementalWorkOrdersDetails = {
    listType: "supplemental_work_order",
    heading: "Supplemental Work Orders",
    tableHeaders: [
        {
            title: "ID",
            key: "_id",
        }
    ],
};

function generateJobOrderApproval(){
    store.dispatch("docGenJobOrderApproval", {
        templateType: "job-order-approval",
        entityId: current.id,
    });
}
function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return 'n/a'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
    if (i === 0) return `${bytes} ${sizes[i]})`
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
}
function getCreatedDate(dateStr){
    const date = new Date(dateStr);
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
    };
    const formattedDate = date.toLocaleString('en-US', options);
    const [datePart, timePart] = formattedDate.split(', ');
    return `${datePart}<br/><span class="created-date">${timePart}</span>`;
}
function getFileName(Key){
    const parts = Key.split("/");
    return parts[parts.length-1];
}
async function download(Key){
    var loadPayload = {
        Key: Key
    };

    await store.dispatch("fetchDownload", loadPayload)
}




// Mutating display data strings
const liquidatedDamagesToDistrict = computed(() => {
    return entityCore.value.liquidated_damages_to_district ? "$" + entityCore.value.liquidated_damages_to_district + " per calendar day" : null;
})
const liquidatedDamagesToContractor = computed(() => {
    return entityCore.value.liquidated_damages_to_contractor ? "$" + entityCore.value.liquidated_damages_to_contractor + " per calendar day" : null;
})
const jambFeePerecentage = computed(() => {
    return entityCore.value.jamb_fee_percentage ? entityCore.value.jamb_fee_percentage + "%" : null;
})
import { useStore } from 'vuex';
const store = useStore();
const fileList = ref([]);
watch(isCurrentEntityLoaded, async () => {
    if(isCurrentEntityLoaded.value){
        fetchSingle("contract", entityCore.value.contract_uid);
        fetchSingle("owner_contact", entityCore.value.owner_project_manager_primary_uid);
        fetchSingle("owner_contact", entityCore.value.owner_project_manager_2_uid);
        fetchSingle("owner_contact", entityCore.value.owner_project_manager_3_uid);
        fetchSingle("contractor_contact", entityCore.value.contractor_project_manager_primary_uid);
        fetchSingle("contractor_contact", entityCore.value.contractor_project_manager_2_uid)
        fetchSingle("contractor_contact", entityCore.value.contractor_project_manager_3_uid);
        fetchSingle("owner_contact", entityCore.value.inspector_uid);
        fetchSingle("owner_contact", entityCore.value.rfp_signing_authority_uid);
        fetchSingle("owner_contact", entityCore.value.ntp_signing_authority_uid);
        fetchSingle("client_contact", entityCore.value.woa_signing_authority_uid);

        fileList.value = await getJobOrderApprovalList();
    }
})
onMounted(() => {
    fetchList("proposal", "work_order-" + current.id);
    fetchList("supplemental_work_order", "work_order-" + current.id);
    
})

async function getJobOrderApprovalList(){
    const entityPath = store.getters.getEntityDocument(current.id)?._path
    const items = await store.dispatch("fetchFileListDataVersionTwo", {
        templateType: "job-order-approval",
        entityPath: entityPath,
    });

    return items || [];
}
const tableHeaders = [
    {
        title: "Key",
        key: "Key",
    },
    // {
    //     title: "Size",
    //     key: "Size",
    // },
    {
        title: "Created",
        key: "LastModified",
    },
    {
        title: "Action",
        key: "Action",
        width: 120,
        sortable: false,
    }
];

</script>
<style>
.created-date {
    font-size: .75rem;
}
</style>