<template>
    <v-container fluid>
        <ReadHeader :current="current" />
            
        <v-row>
            <v-col>
                <KeyValue
                :title="`Contract Number`"
                :value="entityCore.number"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Contract Value`"
                :value="entityCore.value"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`Contract type`"
                :value="entityCore.type"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Contract Procurement Type`"
                :value="entityCore.procurement_type"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col cols="6">
                <KeyValue
                :title="`Contractor`"
                :value="nameById(entityCore.contractor_uid)"
                ></KeyValue>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <KeyValue
                :title="`Start Date`"
                :value="formatDate(entityCore.start_date)"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`End Date`"
                :value="formatDate(entityCore.end_date)"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <KeyValue
                :title="`Award Date`"
                :value="formatDate(entityCore.award_date)"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Final Acceptance Date`"
                :value="formatDate(entityCore.final_acceptance_date)"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col cols="6">
                <KeyValue
                :title="`Notice of Completion Date`"
                :value="formatDate(entityCore.notice_of_completion_date)"
                ></KeyValue>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <h3>Adjustment Factors</h3>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-data-table
                    :headers="adjustmentFactorsListHeaders"
                    :items="entityCore.adjustment_factors"
                    item-key="type"
                    :items-per-page="100"
                    :hide-default-footer="true"
                    disable-sort
                    >
                </v-data-table>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <h3>Options</h3>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-data-table
                    :headers="optionsListHeaders"
                    :items="entityCore.options"
                    item-key="type"
                    :items-per-page="100"
                    :hide-default-footer="true"
                    disable-sort
                    >
                </v-data-table>
            </v-col>
        </v-row>



        <v-row v-if="entityCore.associated_unit_price_books">
            <v-col>
                <h3>Associated Unit Price Books</h3>
                <v-list lines="one">
                    <v-list-item
                        v-for="unitPriceBookId in entityCore.associated_unit_price_books"
                        :key="unitPriceBookId"
                        :title="nameById(unitPriceBookId)"
                        density="compact"
                    ></v-list-item>
                </v-list>
            </v-col>
        </v-row>

    </v-container>
</template>
<script setup>
import ReadHeader from "@/components/dataversiontwo/interface/ReadHeader.vue";
import KeyValue from "@/components/interface/Key-Value.vue";
import adjustmentFactorTypeList from "@/assets/selects/contract-adjustment-factor-type.json";
import { reactive, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useEntityCore, useIsCurrentEntityLoaded, useFetchSingle, useNameById, useFormatting } from "@/assets/modules/composables.js";

const route = useRoute();
const { isCurrentEntityLoaded } = useIsCurrentEntityLoaded();
const isLoading = reactive({});
const alreadyFetchedData = [];
const { fetchSingle } = useFetchSingle(isLoading, alreadyFetchedData);
const { entityCore } = useEntityCore();
const { nameById } = useNameById();
const { formatDate } = useFormatting();

const current = {
    type: "contract",
    id: route.params.entity_id,
};

watch(isCurrentEntityLoaded, async () => {
    if(isCurrentEntityLoaded.value){
        fetchSingle("contractor",entityCore.value.contractor_uid);

        if(entityCore.value.associated_unit_price_books){
            entityCore.value.associated_unit_price_books.forEach((unitPriceBookId) => {
                fetchSingle("unit_price_book",unitPriceBookId);
            });
        }
    }
})
function getTitleFromValue(value) {
    const item = adjustmentFactorTypeList.find(item => item.value === value);
    return item ? item.title : value;
}
const adjustmentFactorsListHeaders = [
    {
        title: "Value",
        key: "value",
    },
    {
        title: "Type",
        key: "type",
        value: (row) => getTitleFromValue(row.type),
    },
];
const optionsListHeaders = [
    {
        title: "Year",
        key: "year",
    },
    {
        title: "Amount",
        key: "amount",
    },
    {
        title: "Start Date",
        key: "start_date",
        value: (row) => formatDate(row.start_date),
    },
    {
        title: "End Data",
        key: "end_date",
        value: (row) => formatDate(row.end_date),
    },
];
</script>