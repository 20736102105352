<template>
    <v-row><v-col><v-divider></v-divider></v-col></v-row>
    
    <v-row>
        <v-col>
            <h3>{{props.details.heading}}</h3>
        </v-col>
        <v-col class="d-flex ga-4 justify-end">
            <v-btn color="primary" variant="outlined" @click="createNew()">
                Create
            </v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-data-table
                :headers="localTableHeaders"
                :items="tableList"
                item-key="_id"
                :items-per-page="100"
                @click:row="tableRowClick"
                :loading="isLoading"
                loading-text="Loading..."
                disable-sort
                >
            </v-data-table>
        </v-col>
    </v-row>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, defineProps, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useTableRowClick } from "@/assets/modules/composables.js";

const props = defineProps(['current', 'details']);
const router = useRouter();
const store = useStore();
const { tableRowClick } = useTableRowClick();

const headerName = {
    title: "Name",
    key: "core.name",
    value: (row) => `${row._name}`,
};
const localTableHeaders = [headerName, ...props.details.tableHeaders];

const isLoading = ref(true);

const tableList = computed(() => {
    return store.getters.getChildList( `${props.current.type}.${props.current.id}.`, props.details.listType);
})
onMounted(() => {
    fetchTableData();
})
async function fetchTableData() {
    await store.dispatch("entityGlobalList", {
        entityType: props.details.listType,
        lookupValue: `${props.current.type}-${props.current.id}`,
    });
    isLoading.value = false;
}

function createNew(){
    router.push(`/dataversiontwo/${props.current.type}/${props.current.id}/${props.details.listType}/create`);
}
</script>