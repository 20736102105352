<template>
    <v-dialog v-model="show" width="auto" persistent>
        <v-card width="800" title="Add External Estimate">
            <v-container>
                <v-form ref="externalEstimateForm" lazy-validation>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="dialogCore.name"
                                label="Name"
                                placeholder="Name"
                                validate-on="blur"
                                @blur="convertEmptyToNull('name')"
                                hint="Minimum of 3 characters required"
                                persistent-hint
                                :rules="[
                                    rules.required,
                                    rules.min(3), 
                                    rules.max(64)
                                ]"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="dialogCore.amount"
                                label="Amount"
                                placeholder="Amount"
                                validate-on="blur"
                                @input="numbersAndDecimalsOnly($event, 'amount');"
                                @blur="convertToNumber('amount')"
                                :rules="[
                                    rules.required,
                                    rules.numberWithDecimal
                                ]"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-select
                                v-model="dialogCore.default_adjustment_factor"
                                :items="adjustmentFactors"
                                item-title="type"
                                item-value="type"
                                label="Default Adjustment Factor*"
                                placeholder="Default Adjustment Factor"
                                @blur="convertEmptyToNull('default_adjustment_factor')"
                                :rules="[
                                    rules.required,
                                ]"
                            >
                            <template v-slot:item="{ props, item }">
                                <v-list-item v-bind="props" :disabled="defaultDisableList(item.value)"></v-list-item>
                            </template>
                            </v-select>
                        </v-col>
                        <v-col>
                            <v-select
                                v-model="dialogCore.npp_adjustment_factor"
                                :items="adjustmentFactors"
                                item-title="type"
                                item-value="type"
                                label="NPP Adjustment Factor*"
                                placeholder="NPP Adjustment Factor"
                                @blur="convertEmptyToNull('npp_adjustment_factor')"
                                :rules="[
                                    rules.required,
                                ]"
                            >
                            <template v-slot:item="{ props, item }">
                                <v-list-item v-bind="props" :disabled="nppDisableList(item.value)"></v-list-item>
                            </template>
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-file-input
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                prepend-icon="mdi-microsoft-excel"
                                label="File input"
                                @change="onFileUpload"
                                v-model="uploadedFile"
                                :rules="[
                                    rules.requiredFileUpload
                                ]"
                            ></v-file-input>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="d-flex ga-4 justify-end">
                            <v-btn color="primary" variant="outlined" @click="dialogCancel()">
                                Cancel
                            </v-btn>
                            <v-btn color="primary" variant="flat" @click="dialogSubmit()">
                                Add
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card>
    </v-dialog>
</template>
<script setup>
import { ref, defineEmits, defineProps, toRefs } from 'vue';
import { useFieldValidation } from "@/assets/modules/composables.js";
import rules from "@/assets/modules/rules.js";
// eslint-disable-next-line no-unused-vars
import ExcelJS from "exceljs";
const props = defineProps(['currentExternalEstimates', 'show', 'adjustmentFactors']);
const { show } = toRefs(props);
const emit = defineEmits(['externalEstimateAdded', 'closeDialog']);
// const { entityAncestry } = useEntityAncestry();
// import { useStore } from 'vuex';

const externalEstimateForm = ref();
const dialogCore = ref({});
const { numbersAndDecimalsOnly, convertToNumber, convertEmptyToNull } = useFieldValidation(dialogCore);
// const store = useStore();
// const isLoading = reactive({});
// const alreadyFetchedData = [];
// const { fetchList } = useFetchList(isLoading, alreadyFetchedData);
const uploadedFile = ref(null);
function defaultDisableList(type) {
    const npp = dialogCore.value.npp_adjustment_factor;
    const isDisabled = npp === type && npp !== undefined;
    return isDisabled;
}
function nppDisableList(type) {
    const npp = dialogCore.value.default_adjustment_factor;
    const isDisabled = npp === type && npp !== undefined;
    return isDisabled;
}

function dialogCancel(){
    emit('closeDialog');
    dialogCore.value = {};
    uploadedFile.value = null;
}
async function dialogSubmit(){
    const formValidation = await externalEstimateForm.value.validate();
    if (!formValidation.valid) {
        throw new Error("Form validation failed");
    }
    emit('externalEstimateAdded', dialogCore.value);
    emit('closeDialog');
    dialogCore.value = {};
    uploadedFile.value = null;
}
/* eslint-disable no-unused-vars */
function onFileUpload(){
    if(uploadedFile.value){
        const reader = new FileReader();
        reader.onloadend = function (){
            const arrayBuffer = reader.result;
            const workbook = new ExcelJS.Workbook();
    
            workbook.xlsx.load(arrayBuffer).then(function (workbook){
                //get sheet 1, the data
                const estimates = workbook.worksheets[0];
                console.log("estimates",estimates)
                let items = sheetToJson({
                    sheet: estimates,
                    headerRowNumber: 1,
                });
                dialogCore.value.spreadsheet = items;
            });
        }
        reader.readAsArrayBuffer(uploadedFile.value);
    }
    console.log("dialogCore.value",dialogCore.value)
}

function sheetToJson({ sheet, headerRowNumber = 1 }){
    let headerRow = sheet.getRow(headerRowNumber);

    headerRow = headerRow._cells.map((cell) => {
        const header = cell.value.replace(/\s/g, "");
        return {
            column: cell._column._number,
            address: cell.address,
            value: header,
        };
    });

    const headers = [];
    headerRow.forEach((cell) => {
        headers[cell.column] = cell.value;
    });

    const payload = [];
    //walk rows and build JSON
    for(var i=1; i<sheet._rows.length; i++){
        const item = {}
        const row = sheet._rows[i]._cells;

        //check for a blank row, stop reading rows
        if(!row[0]){
            break;
        }

        //headers start at column 1, cells start at index 0, REALLY WEIRD
        for(var j=1; j<headers.length; j++){
            const key = headers[j];
            const value = row[(j-1)]?.value;
            item[key] = value;
        }

        //set NPP selection
        if(row[1].fill){
            item["NPP"] = true;
        } else {
          item["NPP"] = false;
        }

        payload.push(item);
    }
    return payload;
}



















// const ownerDoc = computed(() => {
//     return store.getters.getEntityDocument(entityAncestry.value.owner);
// });

// const subcontractorsList = computed(() => {
//     const items = store.getters.getChildList("owner." + entityAncestry.value.owner + ".", "subcontractor");
//     const hint = ownerDoc.value?._name ? `List of subcontractors from ${ownerDoc.value?._name}.` : 'List of subcontractors.'
//     const disabled = !ownerDoc.value?._name;
//     const loading = isLoading['subcontractor_owner-' + entityAncestry.value.owner];
//     return {
//         items: items,
//         hint: hint,
//         disabled: disabled,
//         loading: loading,
//     };
// })

// function isAlreadyChosen(item) {
//     return props.adjustmentFactors.some(adjustmentFactor => adjustmentFactor.type === item);
// }
</script>